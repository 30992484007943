<template>
  <main class="AccountView">
    <div class="container">
      <header class="mb-3">
        <h1>My account</h1>
      </header>

      <!-- User info -->

      <div class="card mb-4">
        <div class="card-header">
          <h2>User information</h2>
        </div>

        <div class="card-body">
          <div class="alert alert-danger" v-if="formUserResponse === 'INVALID'">Erreur</div>
          <div class="alert alert-success" v-if="formUserResponse === 'SUCCESS'">Success</div>

          <form novalidate class="form" @submit.prevent="onSubmitUser">
            <div class="row">
              <div class="col-md-6">
                <div class="mb-3">
                  <FormInput label="First name" :validator="v$.formUser.firstname" :disabled="true" />
                </div>

                <div class="mb-3">
                  <FormInput type="email" label="Email" :validator="v$.formUser.email" :disabled="true" />
                </div>

                <div class="mb-3">
                  <CountrySelect label="Coutry" :validator="v$.formUser.country" :disabled="true" placeholder="" />
                </div>
              </div>

              <div class="col-md-6">
                <div class="mb-3">
                  <FormInput label="Last name" :validator="v$.formUser.lastname" :disabled="true" />
                </div>

                <div class="mb-3">
                  <FormInput label="Phone" :validator="v$.formUser.phone" :disabled="true" />
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>

      <!-- Passwords -->

      <div class="card mb-4">
        <div class="card-header">
          <h2>Password</h2>
        </div>

        <div class="card-body">
          <div class="alert alert-danger" v-if="formPassworResponse === 'ERROR'">
            Something went wrong
          </div>
          <div class="alert alert-danger" v-if="formPassworResponse === 'WRONG_PASSWORD'">
            Wrong Password
          </div>
          <div class="alert alert-success" v-if="formPassworResponse === 'SUCCESS'">
            Password changed
          </div>

          <form novalidate class="form" @submit.prevent="onSubmitPassword">
            <input type="text" name="email" autocomplete="email" hidden :value="formUser.email">

            <div class="row">
              <div class="col-md-6 mb-3 form-required">
                <FormPassword type="password" autocomplete="current-password" label="Current Password" :validator="v$.formPassword.current" />
              </div>
            </div>

            <div class="row">
              <div class="col-md-6 mb-3 form-required">
                <FormPassword type="password" autocomplete="new-password" label="New password" :validator="v$.formPassword.new" hint="At least 1 uppercase, 1 lowercase, 1 digit. 8 chars min." />
              </div>
            </div>

            <div class="row">
              <div class="col-md-6 mb-3 form-required">
                <FormPassword type="password" autocomplete="new-password" label="Confirm password" :validator="v$.formPassword.confirmation" />
              </div>
            </div>

            <div>
              <button class="btn btn-primary" :disabled="v$.formPassword.$invalid">Change password</button>
            </div>
          </form>
        </div>
      </div>
    </div>
  </main>
</template>

<script>
  import FormInput from '@/components/base/FormInput'
  import FormPassword from '@/components/base/FormPassword'
  import CountrySelect from '@/components/CountrySelect'

  import authService from '../services/auth.service'

  import useToast from '@/plugins/toast/useToast'
  import useVuelidate from '@vuelidate/core'
  import { required, email, sameAs } from '@vuelidate/validators'
  import { passwordStrength } from '@/validators/passwordStrength'
  import { mapState, mapActions } from 'vuex'

  export default {
    components: {
      FormInput,
      FormPassword,
      CountrySelect
    },

    setup() {
      return {
        v$: useVuelidate(),
        toast$: useToast()
      }
    },

    data() {
      return {
        formUserResponse: null,
        formPassworResponse: null,
        formUser: {
          firstname: '',
          lastname: '',
          email: '',
          phome: '',
          country: ''
        },
        formPassword: {
          current: '',
          new: '',
          confirmation: ''
        }
      }
    },

    validations() {
      return {
        formUser: {
          firstname: { required },
          lastname: { required },
          email: { required, email }
        },
        formPassword: {
          current: { required },
          new: { required, passwordStrength },
          confirmation: { sameAsPassword: sameAs(this.formPassword.new) }
        }
      }
    },

    computed: {
      ...mapState('auth', [
        'authenticated'
      ])
    },

    async mounted() {
      this.formUser = await authService.getAuthenticated()
    },

    methods: {
      ...mapActions('auth', [
        'getAuthenticated'
      ]),

      onSubmitUser() {
        this.v$.formUser.$touch()
      },

      async onSubmitPassword() {
        this.v$.formPassword.$touch()

        try {
          const response = await authService.changePassword(
            this.formPassword.current,
            this.formPassword.new
          )

          this.v$.formPassword.$reset()

          this.formPassworResponse = 'SUCCESS'
          this.formPassword.current = ''
          this.formPassword.new = ''
          this.formPassword.confirmation = ''

          authService.setToken(response.auth_token)

          this.getAuthenticated()

          this.toast$.success('Well done!', 'The password has been changed.')
        } catch (e) {
          if (e.position_status === 403) {
            this.formPassworResponse = 'WRONG_PASSWORD'
          } else {
            this.formPassworResponse = 'ERROR'
          }
        }
      }
    }
  }
</script>

<style lang="sass" scoped>
  @import '@/sass/styles.vars.all.sass'
</style>
